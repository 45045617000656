import { App } from "./App";
// console.clear();

/**
 * [ ] Turn Antialias on
 *
 *
 */

const container = document.getElementById("app");
console.log("Container", container);
const myApp = new App(container);
myApp.loadAssets().then(myApp.init);

if (module && module.hot) {
  // module.hot.accept((a, b) => {
  //   // For some reason having this function here makes dat gui work correctly
  //   // when using hot module replacement
  // });
  module.hot.dispose(() => {
    if (myApp) myApp.dispose();
  });
}
