export let projectVertex_pars = `
attribute vec3 aPosition;
attribute vec2 aReference;
attribute float aFreq;
attribute vec3 aColor;
uniform sampler2D uPositionTexture;
uniform sampler2D uVelocityTexture;
uniform float uvSize;
varying vec2 vUv;
uniform float uTime;
uniform float uScale;
varying vec3 vColor;
`;
export let projectFragment_pars = `
uniform float uTime;
varying vec3 vColor;
`;

export let projectVertex = `

vec4 pos = texture2D(uPositionTexture, aReference);
vec4 vel = texture2D(uVelocityTexture, aReference);
  float life = vel.a / 100.;
  transformed.xyz *= sin(life*3.14 ) * uScale;

transformed.xyz += pos.xyz;


vec4 mvPosition = modelViewMatrix * vec4(transformed,1.);
gl_Position = projectionMatrix * mvPosition;
vColor = aColor;
`;

export let testVertex = `


transformed += aPosition;


vec4 mvPosition = modelViewMatrix * vec4(transformed,1.);
gl_Position = projectionMatrix * mvPosition;
vColor = aColor;
`;
export let testDepthVertex = `
// instanced
attribute vec2 aReference;
uniform sampler2D uPositionTexture;
uniform sampler2D uVelocityTexture;
attribute vec3 aPosition;
#include <common>
#include <uv_pars_vertex>
#include <displacementmap_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>
void main() {

	#include <uv_vertex>
	#include <skinbase_vertex>
	#ifdef USE_DISPLACEMENTMAP
		#include <beginnormal_vertex>
		#include <morphnormal_vertex>
		#include <skinnormal_vertex>
	#endif
	#include <begin_vertex>
  // instanced
  
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
  
transformed += aPosition;


	#include <project_vertex>
	#include <logdepthbuf_vertex>
  #include <clipping_planes_vertex>
}
`;
export const colorFragment = `
diffuseColor = vec4( vColor, opacity ); // The actual color
`;

export let customDepthVertexShader = `
// instanced
attribute vec2 aReference;
uniform sampler2D uPositionTexture;
uniform sampler2D uVelocityTexture;
attribute vec2 aPosition;
#include <common>
#include <uv_pars_vertex>
#include <displacementmap_pars_vertex>
#include <morphtarget_pars_vertex>
#include <skinning_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>
void main() {

	#include <uv_vertex>
	#include <skinbase_vertex>
	#ifdef USE_DISPLACEMENTMAP
		#include <beginnormal_vertex>
		#include <morphnormal_vertex>
		#include <skinnormal_vertex>
	#endif
	#include <begin_vertex>
  // instanced
  
	#include <morphtarget_vertex>
	#include <skinning_vertex>
	#include <displacementmap_vertex>
  vec4 pos = texture2D(uPositionTexture, aReference);
  vec4 vel = texture2D(uVelocityTexture, aReference);
    float life = vel.a / 100.;
    transformed.xyz *= sin(life*3.14 );

  transformed.xyz += pos.rgb;
  // transformed.yz += aReference * 50.;
	#include <project_vertex>
	#include <logdepthbuf_vertex>
  #include <clipping_planes_vertex>
}
`;

export let createOnBeforeCompile = (uniforms = {}, vertex) => {
  return function onBeforeCompile(material) {
    let keys = Object.keys(uniforms);
    console.log("cmpling dsadsa");
    keys.forEach(key => {
      material.uniforms[key] = uniforms[key];
    });
    material.vertexShader =
      projectVertex_pars +
      material.vertexShader.replace("#include <project_vertex>", vertex);
    material.fragmentShader =
      projectFragment_pars +
      material.fragmentShader.replace(
        "#include <color_fragment>",
        colorFragment
      );
    return material;
  };
};
