import "./styles.css";
import * as THREE from "three";
import { BasicThreeDemo } from "./BasicThreeDemo";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import simulation from "./simulation";
import particles from "./particles";
// import shadowParticles from "./shadowParticles";
import gsap from "gsap";
import { VertexNormalsHelper } from "three";
// import lineParticles from "./lineParticles";
// import lineSimulation from "./lineSimulation";

/**
 *
 * ADDING SHADOWS TO ISNTANCE MATERIALS
 * 1. Add renderer.shadowMap enable to activate your ahsows
 * 2. ADD light.castShadow = 1;
 * 3. Add mesh.castShadow = mesh.receiveShadows = true
 * 4. Add a custom depth mateiral to your mesh to support any instanicng/distortions
 *      Happening inside your material's shader
 *https://discourse.threejs.org/t/shadow-for-instances/7947/2
 http://learningthreejs.com/blog/2012/01/20/casting-shadows/
 * 5. For direction light. Update the depthMaterial to use your distortions
  6.  for point lights you need to update the distance material
 6. You need to modify the camera so the shadow doesnt get cut. and for the items to have shadow onto tehmselves
 https://stackoverflow.com/questions/48355479/three-js-directionallight-and-shadow-cut-off
 */
export function lerp(current, target, speed = 0.1, limit = 0.001) {
  let change = (target - current) * speed;
  if (Math.abs(change) < limit) {
    change = target - current;
  }
  return change;
}

export class App extends BasicThreeDemo {
  constructor(container) {
    super(container);
    this.text = new Text(this);
    this.camera.position.z = 16;
    this.camera.position.x = 14;
    this.camera.position.y = 5;
    //
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    this.scene.background = new THREE.Color("#1c1d1e");
    let testCamera = new THREE.PerspectiveCamera(
      45,
      container.offsetWidth / container.offsetHeight,
      0.1,
      10000
    );
    this.testCamera = testCamera;
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.02;

    // this.controls.enableZoom = false;
    // this.controls.autoRotate = true;
    // this.controls.autoRotateSpeed = 3;
  }
  loadAssets() {
    return new Promise((resolve, reject) => {
      const manager = new THREE.LoadingManager(resolve);
      manager.itemStart("a");
      manager.itemEnd("a");
    });
  }
  init() {
    simulation.init(this.renderer);
    this.addLights();
    particles.init(this.scene);
    // shadowParticles.init(this.scene);
    this.mouse = {
      x: -1000,
      y: -1000
    };
    // window.addEventListener("mousemove", ev => {
    //   if (this.disposed) return;
    //   let viewSize = this.getViewSizeAtDepth();

    //   let x = (ev.clientX / window.innerWidth - 0.5) * viewSize.width;
    //   let y = (-ev.clientY / window.innerHeight + 0.5) * viewSize.height;

    //   this.mouse.x = x;
    //   this.mouse.y = y;

    //   simulation.setMouse(this.mouse);
    // });

    // this.testShadows();
    this.addWall();

    this.tick();
  }
  addWall() {
    let size = 30;
    const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
    let material = new THREE.MeshLambertMaterial({
      color: 0xbbbbbb
      // side: THREE.DoubleSide
    });
    let mesh = new THREE.Mesh(geometry, material);
    mesh.rotation.x = Math.PI / 2;
    mesh.position.y -= 5;
    mesh.receiveShadow = true;
    // this.scene.add(mesh);

    const plane00 = new THREE.Mesh(geometry, material);
    const plane01 = new THREE.Mesh(geometry, material);
    const plane02 = new THREE.Mesh(geometry, material);
    const plane03 = new THREE.Mesh(geometry, material);
    const plane04 = new THREE.Mesh(geometry, material);
    const plane05 = new THREE.Mesh(geometry, material);

    plane00.position.y = size / 2;
    plane00.rotation.x = Math.PI * 0.5;
    plane00.scale.set(size, size, 1);

    plane01.position.y = -size / 2;
    plane01.rotation.x = Math.PI * -0.5;
    plane01.scale.set(size, size, 1);
    plane01.receiveShadow = true;

    plane02.position.z = -size / 2;
    // plane02.rotation.x = Math.PI * 0.5;
    plane02.scale.set(size, size, 1);
    plane02.receiveShadow = true;

    plane03.position.z = size / 2;
    plane03.rotation.y = Math.PI;
    plane03.scale.set(size, size, 1);
    plane03.receiveShadow = true;

    plane04.position.x = size / 2;
    plane04.rotation.y = -Math.PI * 0.5;
    plane04.scale.set(size, size, 1);
    plane04.receiveShadow = true;

    plane05.position.x = -size / 2;
    plane05.rotation.y = Math.PI * 0.5;
    plane05.scale.set(size, size, 1);
    plane05.receiveShadow = true;

    this.scene.add(plane00, plane01, plane02, plane03, plane04, plane05);
  }
  addLights() {
    var ambientLight = new THREE.AmbientLight(0x080808); // soft white ambientLight
    this.scene.add(ambientLight);

    // Point lihgts are really expensive because they have to render six time.
    // One per direction
    // this.scene.add(light);

    let spotLight = new THREE.SpotLight(0xaaaacc, 2, 120, Math.PI / 8, 0.7, 0);
    spotLight.position.set(3 * 2.5, 2 * 2.5, 8 * 2.5);
    spotLight.castShadow = true;
    spotLight.shadow.camera.near = 1;
    spotLight.shadow.camera.far = 2;
    spotLight.shadow.bias = -0.001; // reduces self-shadowing on double-sided objects
    spotLight.shadow.mapSize.width = 512 / 2;
    spotLight.shadow.mapSize.height = 512 / 2;

    // let spotLightHelper = new THREE.SpotLightHelper(spotLight);
    // this.scene.add(spotLightHelper);

    this.scene.add(spotLight);

    // let pointHelper = new THREE.PointLightHelper(light, 2);
    // this.scene.add(pointHelper);

    var light2 = new THREE.PointLight(0xcc8080, 2, 12);
    light2.position.set(-6, 0, 0 - 6);
    this.scene.add(light2);
    var light3 = new THREE.PointLight(0x40bbbb, 1, 6);
    light3.position.set(0, 0, 0);
    this.scene.add(light3);
    var light4 = new THREE.PointLight(0xffbbbb, 14, 4);
    light4.position.set(1, 1, 5);
    this.scene.add(light4);
    // let pointHelper = new THREE.PointLightHelper(light4, 1);
    // this.scene.add(pointHelper);

    // window.addEventListener("click", () => {
    //   if (this.active) {
    //     // gsap.to(particles.uniforms.uScale, {
    //     //   value: 0,
    //     //   duration: 2,
    //     //   ease: "power4.inOut"
    //     // });
    //   }
    //   this.active = true;
    // });
  }
  update() {
    // particles.setTime(this.clock.elapsedTime);
    this.controls.update();
    //
    // if (!this.active) return;
    simulation.render();

    particles.preRender();
  }
}
