import * as THREE from "three";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer.js";
import config from "./config";
import simplex from "glsl-noise/simplex/3d.glsl";

let positionFragment = `
uniform vec2 uRepel;
uniform sampler2D uDefaultPositionTexture;
void main(){
  vec2 uv = gl_FragCoord.xy / resolution;
  vec4 pos = texture2D(positionTexture, uv);
  vec4 vel = texture2D(velocityTexture, uv);

  
  float life = vel.a;


  gl_FragColor = vec4(pos.xyz + vel.xyz, 0.);

  if(life <= 0.){
    gl_FragColor = vec4(texture2D( uDefaultPositionTexture, uv ).xyz, 0.);
  }
}
`;

let velocityFragment = `
uniform sampler2D uDefaultPositionTexture;
${simplex}
// via: https://petewerner.blogspot.jp/2015/02/intro-to-curl-noise.html
vec3 curlNoise( vec3 p ){

  const float e = 0.1;

  float  n1 = snoise(vec3(p.x, p.y + e, p.z));
  float  n2 = snoise(vec3(p.x, p.y - e, p.z));
  float  n3 = snoise(vec3(p.x, p.y, p.z + e));
  float  n4 = snoise(vec3(p.x, p.y, p.z - e));
  float  n5 = snoise(vec3(p.x + e, p.y, p.z));
  float  n6 = snoise(vec3(p.x - e, p.y, p.z));

  float x = n2 - n1 - n4 + n3;
  float y = n4 - n3 - n6 + n5;
  float z = n6 - n5 - n2 + n1;


  const float divisor = 1.0 / ( 2.0 * e );
  return normalize( vec3( x , y , z ) * divisor );
}
  void main(){
    vec2 uv = gl_FragCoord.xy / resolution;
    vec4 oldVel = texture2D(velocityTexture, uv);
    vec3 pos = texture2D(positionTexture, uv).xyz;

    float life = oldVel.a;



    vec4 start = texture2D( uDefaultPositionTexture, uv );
    // vec3 end = start * 2.;


    if( life  <= 0. ){
      life = 100.;
    }
    life += -start.a;

    vec3 cnoise = curlNoise(vec3(.2) * pos);

    

    vec3 change = cnoise *0.03;


    change = oldVel.xyz + ( change - oldVel.xyz) * (0.1); 

    

    gl_FragColor = vec4(change, life);

  }

`;
let _gpuCompute;
let _renderer;
let _positionVariable;
let _velocityVariable;

let square = 20;

let size = square / config.nPixels;
class Simulation {
  render() {
    _gpuCompute.compute();
  }
  init(renderer) {
    _renderer = renderer;
    _gpuCompute = new GPUComputationRenderer(
      config.nPixels,
      config.nPixels,
      _renderer
    );
    let position = _gpuCompute.createTexture();
    let dtPosition = this.createPositionTexture();
    let dtVelocity = _gpuCompute.createTexture();

    _velocityVariable = _gpuCompute.addVariable(
      "velocityTexture",
      velocityFragment,
      dtVelocity
    );

    _positionVariable = _gpuCompute.addVariable(
      "positionTexture",
      positionFragment,
      position
    );
    let defaultPositon = dtPosition;

    _gpuCompute.setVariableDependencies(_positionVariable, [
      _positionVariable,
      _velocityVariable
    ]);
    _gpuCompute.setVariableDependencies(_velocityVariable, [
      _positionVariable,
      _velocityVariable
    ]);

    let positionUniforms = _positionVariable.material.uniforms;
    let velocityUniforms = _velocityVariable.material.uniforms;
    positionUniforms["uDefaultPositionTexture"] = new THREE.Uniform(
      defaultPositon
    );
    velocityUniforms["uDefaultPositionTexture"] = new THREE.Uniform(
      defaultPositon
    );
    _positionVariable.wrapS = THREE.RepeatWrapping;
    _positionVariable.wrapT = THREE.RepeatWrapping;
    var error = _gpuCompute.init();
    if (error !== null) {
      console.error(error);
    }
  }
  createPositionTexture() {
    let texture = _gpuCompute.createTexture();
    let posArray = texture.image.data;

    for (var k = 0, kl = posArray.length; k < kl; k += 4) {
      let index = k / 4;

      let x = Math.random() - 0.5;
      let y = Math.random() - 0.5;
      let z = Math.random() - 0.5;
      let magnitude = Math.sqrt(x * x + y * y + z * z);
      x /= magnitude;
      y /= magnitude;
      z /= magnitude;

      let diameter = Math.sqrt(Math.random()) * 1;

      // posArray[k + 0] = x * size - square / 2;
      posArray[k + 0] = x * diameter;
      // posArray[k + 1] = y * size - square / 2;
      posArray[k + 1] = y * diameter;
      posArray[k + 2] = z * diameter * 5;
      posArray[k + 3] = Math.random() * 0.3 + 0.2;
    }

    return texture;
  }
  getPositionTexture() {
    return _gpuCompute.getCurrentRenderTarget(_positionVariable).texture;
  }
  getVelocityTexture() {
    return _gpuCompute.getCurrentRenderTarget(_velocityVariable).texture;
  }
}
export default new Simulation();
