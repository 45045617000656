import isMobile from "is-mobile";
import { getGPUTier } from "detect-gpu";

let GPUTier = getGPUTier();

let nInstances = 64;

// if(GPUTier.tier.indexOf("TIER_1") > -1){
//   nInstances *= 2;
// }
// if(GPUTier.tier.indexOf("TIER_2") > -1){
//   nInstances *= 2;
// }
// if(GPUTier.tier.indexOf("TIER_3") > -1){
//   nInstances *= 2;
// }
let tier = GPUTier.tier;
let tierNumber = Number(tier.substring(tier.length - 1));

for (let i = 0; i < tierNumber; i++) {
  nInstances *= 2;
}
// nInstances = nInstances * 3 * 2;

export default {
  nPixels: nInstances
};
// export default {
//   nPixels: nInstances
// };
