import * as THREE from "three";
import simulation from "./simulation";
import config from "./config";
import {
  createOnBeforeCompile,
  customDepthVertexShader,
  projectVertex
} from "./utils";
let testLight = true;

class Particles {
  init(scene) {
    let geometry = this.createGeometry();
    const material = new THREE.MeshLambertMaterial({
      color: 0x5599b8,
      combine: THREE.MultiplyOperation
    });

    this.uniforms = {
      uPositionTexture: new THREE.Uniform(null),
      uVelocityTexture: new THREE.Uniform(null),
      uvSize: new THREE.Uniform(1 / config.nPixels),
      uTexture: new THREE.Uniform(null),
      uTime: new THREE.Uniform(0),
      uScale: new THREE.Uniform(1)
    };

    let uniforms = this.uniforms;

    material.onBeforeCompile = createOnBeforeCompile(
      this.uniforms,
      projectVertex
    );

    var customDepthMaterial = new THREE.MeshDepthMaterial();

    // You need to also give the depth material the unforms you need.
    // We use the same onse so we don't have to update both of them. Only
    // Update once
    customDepthMaterial.onBeforeCompile = function(shader) {
      let keys = Object.keys(uniforms);
      keys.forEach(key => {
        shader.uniforms[key] = uniforms[key];
      });
      shader.vertexShader = customDepthVertexShader;
      // shader.fragmentShader =
      //   "#define DEPTH_PACKING 3201" + "\n" + shader.fragmentShader;
    };
    customDepthMaterial.depthPacking = THREE.RGBADepthPacking;

    // let customDistanceMaterial = new THREE.MeshDistanceMaterial();
    // customDistanceMaterial.onBeforeCompile = createOnBeforeCompile(
    //   this.uniforms,
    //   projectVertex
    // );

    let mesh = new THREE.Mesh(geometry, material);
    this.mesh = mesh;
    mesh.customDepthMaterial = customDepthMaterial;
    // mesh.customDistanceMaterial = customDistanceMaterial;
    mesh.receiveShadow = true;
    mesh.castShadow = true;
    mesh.frustumCulled = false;
    // console.log(this.mesh);
    scene.add(mesh);
    mesh.matrixAutoUpdate = false;
  }
  createGeometry() {
    let size = 10 / config.nPixels;
    let baseGeometry = new THREE.SphereBufferGeometry(size, 8, 8);
    let instanced = new THREE.InstancedBufferGeometry().copy(baseGeometry);
    // instanced.maxInstancedCount = config.nPixels * config.nPixels;
    let aPosition = [];
    let aReference = [];
    let aFreq = [];
    let aColor = [];
    let separation = 0.5;
    let colors = [new THREE.Color("#aa3030"), new THREE.Color("#121214")];
    for (let y = 0; y < config.nPixels; y++) {
      for (let x = 0; x < config.nPixels; x++) {
        aPosition.push(x * separation - (config.nPixels / 2) * separation);
        aPosition.push(y * separation - (config.nPixels / 2) * separation);

        aReference.push(x / config.nPixels);
        aReference.push(y / config.nPixels);

        aFreq.push(Math.random() * 0.5 + 0.5);

        let colorIndex = Math.floor(Math.random() * colors.length);

        let color = colors[colorIndex];

        aColor.push(color.r, color.g, color.b);
      }
    }
    instanced.addAttribute(
      "aPosition",
      new THREE.InstancedBufferAttribute(new Float32Array(aPosition), 2, false)
    );
    instanced.addAttribute(
      "aReference",
      new THREE.InstancedBufferAttribute(new Float32Array(aReference), 2, false)
    );
    instanced.addAttribute(
      "aFreq",
      new THREE.InstancedBufferAttribute(new Float32Array(aFreq), 1, false)
    );
    instanced.addAttribute(
      "aColor",
      new THREE.InstancedBufferAttribute(new Float32Array(aColor), 3, false)
    );

    return instanced;
  }
  setTime(time) {
    this.uniforms.uTime.value = time;
  }
  preRender() {
    this.uniforms.uPositionTexture.value = simulation.getPositionTexture();
    this.uniforms.uVelocityTexture.value = simulation.getVelocityTexture();
  }
}

export default new Particles();
